import { CatalogData } from "~/types/api/catalog.types";

export const getProductImages = (product: CatalogData) => {
  const variantImage = product?.variant_plp_image?.image?.url
    ? product?.variant_plp_image?.image
    : null;
  const baseDefaultConfigImage = product?.default_config?.medias?.find(
    (img) => img.position === "PLP",
  );
  if (product?.images?.length) {
    const images = [...product.images];
    if (images.length && variantImage) {
      images[0] = variantImage;
    } else if (images.length && baseDefaultConfigImage?.file) {
      images[0] = baseDefaultConfigImage?.file;
    }
    return images;
  } else {
    return variantImage
      ? [variantImage]
      : baseDefaultConfigImage
        ? [baseDefaultConfigImage.file]
        : [];
  }
};
